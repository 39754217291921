button, .btn{
    border: 0;
    background: #2A913E;
    color: white;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 0.8rem;
    padding: 1px 35px;
    border-radius: 2px;
    line-height: 22px;
    text-decoration: none;
}